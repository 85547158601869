import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/reevesdotnl/reevesdotnl/src/templates/pdf-view.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Geological Society has announced a two-day meeting `}<strong parentName="p">{`Petroleum geology of the southern South Atlantic`}</strong>{`, 6-7 October 2021, at the Society's premises in London.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.reeves.nl/upload/south-atlantic-poster.pdf"
        }}>{`Click here to see the flyer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.reeves.nl/upload/ReevesAnimationLondon2021.gif"
        }}>{`Click here to see the animation from the Reeves & Souza abstract`}</a></li>
    </ul>
    <p><em parentName="p">{`Last updated: 2021 August 12`}</em></p>
    <p><a parentName="p" {...{
        "href": "../other-material"
      }}>{`Return to Global Thoughts page`}</a></p>
    <p><a parentName="p" {...{
        "href": "../../gondwana"
      }}>{`Return to Gondwana main page`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      